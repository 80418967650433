var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu flex-row-center" },
        [
          _vm.device !== "mobile" ? void 0 : _vm._e(),
          _c(
            "el-badge",
            {
              attrs: {
                value: _vm.currentUploadingFiles.length,
                max: 99,
                hidden: _vm.currentUploadingFiles.length === 0,
              },
            },
            [
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("settings.transferList") } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "circle-button flex-center",
                      on: { click: _vm.showUploadProgress },
                    },
                    [_c("i", { staticClass: "iconfont icon-fanyebeifen" })]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-badge",
            {
              attrs: {
                value: _vm.filteredMessages.length,
                max: 99,
                hidden: _vm.filteredMessages.length === 0,
              },
            },
            [
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("settings.messageNoice") } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom-start",
                        width: "340",
                        trigger: "click",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "circle-button flex-center",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [_c("i", { staticClass: "el-icon-bell" })]
                      ),
                      _c("message-notice", {
                        attrs: { messageList: _vm.messageList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper ml32 flex-row-center" },
                [
                  _c("img", {
                    staticClass: "user-avatar",
                    attrs: { src: _vm.avatar },
                  }),
                  _c("span", { staticClass: "ml8 mr8 user-name" }, [
                    _vm._v(_vm._s(_vm.nickName)),
                  ]),
                  _c("i", { staticClass: "iconfont icon-xialajiantou " }),
                ]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/profile" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(_vm._s(_vm.$t("settings.personalCenter"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.setting = true
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.layoutSetting"))),
                      ]),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("settings.logout")))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "publicDialog",
          attrs: {
            width: "600px",
            visible: _vm.progressDialogVisible,
            "append-to-body": true,
            "before-close": _vm.closeDialog,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.progressDialogVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("settings.transferProgress"))),
              ]),
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.closeDialog },
              }),
            ]),
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("settings.uploadComplete")) +
                "（" +
                _vm._s(_vm.uploadedCount) +
                "/" +
                _vm._s(_vm.totalFiles) +
                "）"
            ),
          ]),
          _c("p", { staticClass: "color-gray" }, [
            _vm._v("-" + _vm._s(_vm.$t("settings.showCurrentUpload")) + "-"),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "max-height": "300px",
                "overflow-y": "auto",
                "padding-bottom": "10px",
              },
            },
            _vm._l(_vm.fileList, function (file, index) {
              return _c("div", { key: file.uid, staticClass: "mt10" }, [
                _c("div", [_vm._v(_vm._s(file.name))]),
                _c(
                  "div",
                  { staticClass: "flexIcon flex-row-center" },
                  [
                    _c("el-progress", {
                      staticClass: "f-grow",
                      attrs: {
                        status: _vm.progressList[index].status
                          ? _vm.progressList[index].status
                          : undefined,
                        "stroke-width": 20,
                        percentage: _vm.progressList[index].percentage || 0,
                        "text-inside": true,
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "f-shrink flex-row-center",
                        staticStyle: { width: "70px" },
                      },
                      [
                        _vm.progressList[index].status === "exception"
                          ? _c("i", {
                              staticClass: "iconfont icon-gongzuotai1",
                            })
                          : _vm._e(),
                        _vm.progressList[index].status === "exception"
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: _vm.$t("settings.reUpload"),
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-shuaxin",
                                  on: {
                                    click: function ($event) {
                                      return _vm.reUpload(file)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm.progressList[index].status === "success"
                          ? _c("i", { staticClass: "iconfont icon-a-dacha3" })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }