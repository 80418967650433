<template>
  <div
    class="sidebar-logo-container"
    :class="{'collapse':collapse}"
    :style="{ backgroundColor: sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightLogoBackground }"
  >
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <el-badge
          :value="testTitle"
          class="item"
          :hidden="!isTestEnv"
        >
          <img
            v-if="logo"
            :src="logo"
            class="sidebar-logo"
          />
        </el-badge>
        <!-- <h1 v-else class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }} </h1> -->
      </router-link>
      <router-link
        v-else
        key="expand"
        class="sidebar-logo-link"
        to="/"
      >
        <el-badge
          :value="testTitle"
          class="item"
          :hidden="!isTestEnv"
        >
          <img
            v-if="logo"
            :src="logo"
            class="sidebar-logo"
          />
        </el-badge>
        <!-- <h1 class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }} </h1> -->
      </router-link>
    </transition>
  </div>
</template>

<script>
import logoImg from "@/assets/logo/logo.png";
import variables from "@/assets/styles/variables.scss";

export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    variables() {
      return variables;
    },
    sideTheme() {
      return this.$store.state.settings.sideTheme;
    },
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      logo: logoImg,
      isTestEnv:
        process.env.VUE_APP_ENV === "test" ||
        process.env.VUE_APP_ENV === "development",
      testTitle: process.env.VUE_APP_ENV === "test" ? "测试版" : "开发版",
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #2b2f3a;
  padding-left: 20px;
  // text-align: center;
  overflow: hidden;
  border-bottom: 1px solid #ededed;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 136px;
      height: 32px;
      vertical-align: middle;
      // margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 64px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
::v-deep .el-badge__content.is-fixed {
  top: 10px;
}
</style>
