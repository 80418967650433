import { msgList, msgDetail, sendMsg } from '@/api/message'
import { getCurrentFormattedTime } from '@/utils'

const state = {
  isShowMsg: false,
  accountList: [],
  chatMsgList: [],
  sendInfo: {},
  myInfoData: {},
  msgLoading: false,
  accountLoading: false
}

const mutations = {
  SET_IS_SHOW_MSG(state, payload) {
    state.isShowMsg = payload
  },
  SET_ACCOUNT_LIST(state, payload) {
    state.accountList = payload
  },
  SET_MESSAGE_LIST(state, payload) {
    state.chatMsgList = payload
  },
  SET_SEND_INFO(state, payload) {
    state.sendInfo = payload
  },
  SET_MY_INFO_DATA(state, payload) {
    state.myInfoData = payload
  },
  SET_MSG_LOADING(state, payload) {
    state.msgLoading = payload
  },
  SET_ACCOUNT_LOADING(state, payload) {
    state.accountLoading = payload
  },
  SET_MESSAGE_PUSH(state, payload) {
    const len = state.chatMsgList.length
    const { msgState } = payload
    if (msgState === 'send') {
      state.chatMsgList.push({
        avatar: state.myInfoData.myProfileImage,
        userName: state.myInfoData.myNickname,
        time: getCurrentFormattedTime(),
        formMe: true,
        ...payload,
      })
    } else if (msgState === 'success') {
      state.chatMsgList[len - 1].msgState = 'success'
    } else if (msgState === 'error') {
      state.chatMsgList[len - 1].msgState = 'error'
    } else if (msgState === 'abort') {
      state.chatMsgList[len - 1].msgState = 'send'
    }
  }
}

const actions = {
  chinaTime(time) {
    const utcDate = new Date(time);
    const year = utcDate.getFullYear();
    const month = String(utcDate.getMonth() + 1).padStart(2, '0');
    const day = String(utcDate.getDate()).padStart(2, '0');
    const hour = String(utcDate.getHours()).padStart(2, '0');
    const minute = String(utcDate.getMinutes()).padStart(2, '0');
    const second = String(utcDate.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  },
  async sendMessage({ commit }, payload) {
    try {
      const { isSend, isFile } = payload
      if (isSend) {
        commit('SET_MESSAGE_PUSH', { ...payload, msgState: 'send' })
      }
      if (!isSend) {
        commit('SET_MESSAGE_PUSH', { ...payload, msgState: 'abort' })
      }
      if (isFile) {
        await sendMsg({ ...state.sendInfo, mediaUrls: payload.mediaUrls, message: '文件上传' })
      } else {
        await sendMsg({ ...state.sendInfo, message: payload.message })
      }
      commit('SET_MESSAGE_PUSH', { ...payload, msgState: 'success' })
    } catch (e) {
      console.log(e);
      commit('SET_MESSAGE_PUSH', { ...payload, msgState: 'error' })
    }
  },
  // sendFileMessage()
  async getDetail({ commit }, payload) {
    try {
      commit('SET_MSG_LOADING', true)
      commit('SET_MESSAGE_LIST', [])
      commit('SET_IS_SHOW_MSG', false)
      console.log(payload, '参数');
      const { data } = await msgDetail({
        profileId: payload.platform === 'twitter' ? payload.profileId : '',
        platform: payload.platform,
        conversationId: payload.conversationId
      })
      if (data && data.length) {
        const newList = data.filter(item => item.senderDetails !== null).map(item => {
          const detail = JSON.parse(item.senderDetails)
          let otherMessage = null
          if (item.attachments) {
            otherMessage = JSON.parse(item.attachments)
          }
          return {
            ...item,
            avatar: item.action === 'received' ? detail.profileImage : payload.myProfileImage,
            formMe: item.action === 'sent',
            // time: actions.chinaTime(item.created),
            time: item.created,
            userName: detail.name,
            messageType: otherMessage ? otherMessage[0].type ? otherMessage[0].type : '' : '',
            msgUrl: otherMessage ? otherMessage[0].url ? otherMessage[0].url : '' : ''
          }
        })
        commit('SET_MY_INFO_DATA', payload)
        commit('SET_SEND_INFO', {
          conversationId: newList[0].conversationId,
          recipientId: newList[0].action === 'sent' ? newList[0].recipientId : newList[0].senderId,
          profileId: payload.platform === 'twitter' ? payload.profileId : '',
          platform: payload.platform,
        })
        commit('SET_MESSAGE_LIST', newList)
        commit('SET_IS_SHOW_MSG', true)
      }
      commit('SET_MSG_LOADING', false)
    } catch (e) {
      console.log(e);
      commit('SET_MSG_LOADING', false)
    }

  },
  async getMsgList({ commit }, payload) {
    try {
      commit('SET_ACCOUNT_LOADING', true)
      commit('SET_IS_SHOW_MSG', false)
      const { data } = await msgList(payload)
      const newList = data.map(item => {
        if (item.children && item.children.length) {
          return {
            ...item,
            children: item.children.map(child => {
              return { ...child, isActive: false }
            })
          }
        } else {
          return { ...item }
        }
      });
      commit('SET_ACCOUNT_LIST', newList)
      commit('SET_ACCOUNT_LOADING', false)
    } catch (e) {
      console.log(e);
      commit('SET_ACCOUNT_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
