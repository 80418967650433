<template>
  <div class="message-notice flex-column">
    <div class="flex-row-center f-between f-shrink">
      <span>消息列表</span>
      <el-select
        v-model="messageType"
        style="width: 110px; margin-right: 10px;"
      >
        <el-option
          label="全部类型"
          :value="0"
        ></el-option>
        <el-option
          v-for="dict in dict.type.notice_message_type"
          :key="dict.value"
          :label="dict.label"
          :value="dict.value"
        >
          <span>{{ dict.label }}</span>
        </el-option>
      </el-select>
    </div>
    <div class="message-line"></div>
    <div
      class="f-grow"
      style="overflow-y: auto;"
    >
      <el-empty
        :image-size="200"
        v-if="filteredMessages.length==0"
      ></el-empty>
      <!-- 循环渲染通知列表 -->
      <div
        v-for="(item, index) in filteredMessages"
        :key="index"
        class="message-item"
      >
        <div class="flex-row-center mb5 f-between">
          <div class="flex-row-center">
            <svg-icon
              :icon-class="getMessageSvg(item.messageType)"
              class="message-notice-svg-icon"
            />
            <dict-tag
              class="notice-title"
              :options="dict.type.notice_message_type"
              :value="item.messageType"
            />
          </div>
          <span class="time">{{ timeAgo(item.time) }}</span>
        </div>
        <el-tooltip content="点击即代表已读">
          <div
            class="content"
            @click="handleClickMessage(item)"
          >
            {{ item.content }}
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="message-line"></div>
    <div class="flex-row-center f-between f-shrink">
      <!-- 全部标记为已读按钮 -->
      <div
        class="flex-row-center notice-btn"
        @click="markAllAsRead"
      >
        <i class="el-icon-success mr8"></i>
        全部标记为已读
      </div>
      <div
        class="notice-btn"
        @click="showHistoryNotifications"
      >
        历史通知
      </div>
    </div>

    <!-- 历史通知弹窗 -->
    <el-dialog
      class="publicDialog"
      width="70%"
      :visible.sync="showHistory"
      :append-to-body="true"
      :show-close="false"
      :before-close="handleCloseHistory"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">消息列表</div>
          <i
            class="el-icon-close"
            @click="showHistory = false"
          ></i>
        </div>
      </div>
      <div class="flex-row-center f-between">
        <el-select
          class="history-select"
          v-model="historyMessageType"
          style="width: 110px; margin-right: 10px;"
        >
          <el-option
            label="全部类型"
            :value="0"
          ></el-option>
          <el-option
            v-for="dict in dict.type.notice_message_type"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          >
            <span>{{ dict.label }}</span>
          </el-option>
        </el-select>
        <div
          class="flex-row-center notice-btn"
          @click="markAllAsRead"
        >
          <i class="el-icon-success mr8"></i>
          全部标记为已读
        </div>
      </div>
      <div style="height: 500px; overflow-y: auto;">
        <div v-if="filteredHistoryMessages.length === 0">
          <el-empty :image-size="200"></el-empty>
        </div>
        <div
          v-for="(item, index) in filteredHistoryMessages"
          :key="index"
          class="message-item"
        >
          <div class="flex-row-center mb5 f-between">
            <div class="flex-row-center">
              <svg-icon
                :icon-class="getMessageSvg(item.messageType)"
                class="message-notice-svg-icon"
              />
              <dict-tag
                class="notice-title"
                :options="dict.type.notice_message_type"
                :value="item.messageType"
              />
            </div>
            <span class="time">{{ timeAgo(item.time) }}</span>
          </div>
          <el-tooltip content="点击即代表已读">
            <div
              class="content"
              @click="handleClickMessage(item)"
            >
              {{ item.content }}
            </div>
          </el-tooltip>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { readNoticeMessage } from "../../../api/notice";

export default {
  components: {},
  dicts: ["notice_message_type"],
  props: {
    messageList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      messageType: 0, // 当前筛选的消息类型
      showHistory: false, // 控制历史通知弹窗显示
      historyMessages: [], // 保存历史通知列表
      historyMessageType: 0, // 历史通知筛选类型
    };
  },
  computed: {
    // 根据筛选条件过滤消息
    filteredMessages() {
      if (this.messageType === 0) {
        return this.messageList.filter((item) => !item.isRead);
      }
      return this.messageList.filter(
        (item) => item.messageType === this.messageType && !item.isRead
      );
    },
    filteredHistoryMessages() {
      if (this.historyMessageType === 0) {
        return this.historyMessages;
      }
      return this.historyMessages.filter(
        (item) => item.messageType === this.historyMessageType
      );
    },
  },
  created() {},
  methods: {
    getMessageSvg(type) {
      if (type === "system") {
        return "message_notice_system";
      } else if (type === "package") {
        return "message_notice_package";
      } else if (type === "activity") {
        return "message_notice_activity";
      } else {
        return "message_notice_system";
      }
    },
    handleClickMessage(item) {
      item.isRead = true;
      this.$emit("update:messageList", this.messageList);
      if (!item.isRead) {
        readNoticeMessage({ id: item.id });
      }
      // 社交账号异常时跳转到账号管理页面
      if (item.messageType2 === "social_account_abnormal") {
        this.$router.push({ path: "/account" });
        this.showHistory = false;
      }
    },
    // 标记全部为已读
    markAllAsRead() {
      readNoticeMessage().then(() => {
        this.messageList.forEach((item) => {
          item.isRead = true;
        });
        this.$emit("update:messageList", this.messageList);
      });
    },

    // 显示历史通知弹窗
    showHistoryNotifications() {
      this.historyMessages = [...this.messageList]; // 获取历史通知
      this.showHistory = true;
    },

    // 关闭历史通知弹窗
    handleCloseHistory(done) {
      this.showHistory = false;
      done();
    },
  },
};
</script>
<style lang="scss" scoped>
.message-notice {
  height: 470px;
}
::v-deep .el-input--medium .el-input__inner {
  height: 36px;
  line-height: 36px;
  border: none;
  text-align: right;
  padding-right: 10px !important;
}
::v-deep .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -15px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}
.history-select ::v-deep .el-input__inner {
  text-align: left;
}
.history-select ::v-deep .el-input__suffix {
  right: 10px;
}

.message-item {
  padding: 10px;
  //border-bottom: 1px solid #ddd;
}
.message-line {
  width: 100%;
  height: 1px;
  background: #d2d9e4;
  margin: 10px 0;
}
.content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-left: 24px;
}
.content:hover {
  color: #38007d;
  text-decoration-line: underline;
  cursor: pointer;
}
.read {
  color: #ccc;
}
.notice-title {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 12px;
  color: #667085;
  line-height: 18px;
  text-align: left;
  font-style: normal;
}
.time {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #667085;
  line-height: 18px;
  text-align: right;
  font-style: normal;
}
.dialog-footer {
  text-align: center;
}
.message-notice-svg-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.notice-btn {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  cursor: pointer;
}
.notice-btn:hover {
  color: #38007d;
}
.el-icon-success {
  font-size: 16px;
}
</style>
