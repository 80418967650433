<template>
  <div
    :class="{'has-logo':showLogo}"
    :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground}"
  >
    <logo
      v-if="showLogo"
      :collapse="isCollapse"
    />
    <el-scrollbar
      :class="settings.sideTheme"
      wrap-class="scrollbar-wrapper"
    >
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
        :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
        mode="vertical"
        @select="selectMenu"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path  + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";
import EventBus from "@/utils/EventBus";

export default {
  components: { SidebarItem, Logo },
  data() {
    return {};
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebarRouters", "sidebar"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  methods: {
    selectMenu(key, keyPath) {
      console.log(key, keyPath);
      if (key == "/material") {
        EventBus.$emit("matreial-menu-click");
      }
    },
  },
};
</script>

<style lang="scss">
.el-menu--collapse .el-menu-item {
  margin: 0 0 !important;
}

.el-menu--collapse .el-submenu {
  margin: 0 0 !important;
}
.el-menu {
  // margin-top: 40px;
  .el-menu-item {
    margin: 0 8px;
    height: 40px;
    margin-bottom: 8px;
    line-height: 40px;
  }
  .el-menu-item.is-active {
    color: #0156ff !important;
    background: #e2ebfd !important;
    border-radius: 4px !important;
    height: 40px;
    line-height: 40px;
  }
  .el-submenu__title {
    height: 40px;
    line-height: 40px;
  }

  .is-active {
    .el-submenu__title {
      color: #0156ff !important;
      background: #e2ebfd !important;
      border-radius: 4px !important;
      height: 40px;
      line-height: 40px;
    }
  }

  .el-submenu {
    margin: 0 8px;
  }
  .el-submenu .el-menu {
    margin-top: 10px;
    .el-menu-item.is-active {
      color: #0156ff !important;
      background: #e2ebfd !important;
      border-radius: 4px !important;
      // margin: 0 20px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
