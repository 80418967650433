var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-notice flex-column" },
    [
      _c(
        "div",
        { staticClass: "flex-row-center f-between f-shrink" },
        [
          _c("span", [_vm._v("消息列表")]),
          _c(
            "el-select",
            {
              staticStyle: { width: "110px", "margin-right": "10px" },
              model: {
                value: _vm.messageType,
                callback: function ($$v) {
                  _vm.messageType = $$v
                },
                expression: "messageType",
              },
            },
            [
              _c("el-option", { attrs: { label: "全部类型", value: 0 } }),
              _vm._l(_vm.dict.type.notice_message_type, function (dict) {
                return _c(
                  "el-option",
                  {
                    key: dict.value,
                    attrs: { label: dict.label, value: dict.value },
                  },
                  [_c("span", [_vm._v(_vm._s(dict.label))])]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "message-line" }),
      _c(
        "div",
        { staticClass: "f-grow", staticStyle: { "overflow-y": "auto" } },
        [
          _vm.filteredMessages.length == 0
            ? _c("el-empty", { attrs: { "image-size": 200 } })
            : _vm._e(),
          _vm._l(_vm.filteredMessages, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "message-item" },
              [
                _c("div", { staticClass: "flex-row-center mb5 f-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-row-center" },
                    [
                      _c("svg-icon", {
                        staticClass: "message-notice-svg-icon",
                        attrs: {
                          "icon-class": _vm.getMessageSvg(item.messageType),
                        },
                      }),
                      _c("dict-tag", {
                        staticClass: "notice-title",
                        attrs: {
                          options: _vm.dict.type.notice_message_type,
                          value: item.messageType,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "time" }, [
                    _vm._v(_vm._s(_vm.timeAgo(item.time))),
                  ]),
                ]),
                _c("el-tooltip", { attrs: { content: "点击即代表已读" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "content",
                      on: {
                        click: function ($event) {
                          return _vm.handleClickMessage(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.content) + " ")]
                  ),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "message-line" }),
      _c("div", { staticClass: "flex-row-center f-between f-shrink" }, [
        _c(
          "div",
          {
            staticClass: "flex-row-center notice-btn",
            on: { click: _vm.markAllAsRead },
          },
          [
            _c("i", { staticClass: "el-icon-success mr8" }),
            _vm._v(" 全部标记为已读 "),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "notice-btn",
            on: { click: _vm.showHistoryNotifications },
          },
          [_vm._v(" 历史通知 ")]
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "publicDialog",
          attrs: {
            width: "70%",
            visible: _vm.showHistory,
            "append-to-body": true,
            "show-close": false,
            "before-close": _vm.handleCloseHistory,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showHistory = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [_vm._v("消息列表")]),
              _c("i", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    _vm.showHistory = false
                  },
                },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-row-center f-between" },
            [
              _c(
                "el-select",
                {
                  staticClass: "history-select",
                  staticStyle: { width: "110px", "margin-right": "10px" },
                  model: {
                    value: _vm.historyMessageType,
                    callback: function ($$v) {
                      _vm.historyMessageType = $$v
                    },
                    expression: "historyMessageType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部类型", value: 0 } }),
                  _vm._l(_vm.dict.type.notice_message_type, function (dict) {
                    return _c(
                      "el-option",
                      {
                        key: dict.value,
                        attrs: { label: dict.label, value: dict.value },
                      },
                      [_c("span", [_vm._v(_vm._s(dict.label))])]
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "flex-row-center notice-btn",
                  on: { click: _vm.markAllAsRead },
                },
                [
                  _c("i", { staticClass: "el-icon-success mr8" }),
                  _vm._v(" 全部标记为已读 "),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { height: "500px", "overflow-y": "auto" } },
            [
              _vm.filteredHistoryMessages.length === 0
                ? _c(
                    "div",
                    [_c("el-empty", { attrs: { "image-size": 200 } })],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.filteredHistoryMessages, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "message-item" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-row-center mb5 f-between" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-row-center" },
                          [
                            _c("svg-icon", {
                              staticClass: "message-notice-svg-icon",
                              attrs: {
                                "icon-class": _vm.getMessageSvg(
                                  item.messageType
                                ),
                              },
                            }),
                            _c("dict-tag", {
                              staticClass: "notice-title",
                              attrs: {
                                options: _vm.dict.type.notice_message_type,
                                value: item.messageType,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.timeAgo(item.time))),
                        ]),
                      ]
                    ),
                    _c("el-tooltip", { attrs: { content: "点击即代表已读" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          on: {
                            click: function ($event) {
                              return _vm.handleClickMessage(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.content) + " ")]
                      ),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }